<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules" v-if="reMountedForm">
      <a-form-model-item label="设备编号" prop="deviceId">
        <a-input v-model="form.deviceId" :disabled="readOnly" placeholder="请输入设备编号" />
      </a-form-model-item>
      <a-form-model-item label="在线状态" prop="online">
        <a-select placeholder="请选择在线状态" :disabled="readOnly" v-model="form.online">
          <a-select-option :value="item.dictValue" v-for="item in onlineOptions" :key="item.dictValue">{{
            item.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="设备时间" prop="deviceTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.deviceTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
          :disabled="readOnly"
        />
      </a-form-model-item>
      <a-form-model-item label="当前状态的sim卡号" prop="simNo">
        <a-input v-model="form.simNo" :disabled="readOnly" placeholder="请输入当前状态的sim卡号" />
      </a-form-model-item>
      <a-form-model-item label="当前状态的车牌号" prop="plateNo">
        <a-select
          show-search
          allowClear
          placeholder="请输入当前状态的车牌号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleLicensePlateNumSearch"
          @change="handleLicensePlateNumChange"
          :loading="loading"
          v-model="form.plateNo"
          :disabled="readOnly"
        >
          <a-select-option v-for="d in vehicleNoArray" :key="d.licensePlateNum" :value="d.licensePlateNum">
            {{ d.licensePlateNum }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="报警标志" prop="warnBit">
        <a-input v-model="form.warnBit" placeholder="请输入报警标志" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="状态" prop="statusBit">
        <a-input v-model="form.statusBit" placeholder="请输入状态" />
      </a-form-model-item> -->
      <a-form-model-item label="GPS经度" prop="longitude">
        <a-input v-model="form.longitude" :disabled="readOnly" placeholder="请输入GPS经度" />
      </a-form-model-item>
      <a-form-model-item label="GPS纬度" prop="latitude">
        <a-input v-model="form.latitude" :disabled="readOnly" placeholder="请输入GPS纬度" />
      </a-form-model-item>
      <a-form-model-item label="高程(米)" prop="altitude">
        <a-input v-model="form.altitude" :disabled="readOnly" placeholder="请输入高程(米)" />
      </a-form-model-item>
      <a-form-model-item label="速度(1/10公里每小时)" prop="speed">
        <a-input v-model="form.speed" :disabled="readOnly" placeholder="请输入速度(1/10公里每小时)" />
      </a-form-model-item>
      <a-form-model-item label="方向" prop="direction">
        <a-input v-model="form.direction" :disabled="readOnly" placeholder="请输入方向" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="saveLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDeviceStatus, addDeviceStatus, updateDeviceStatus } from '@/api/jt808/deviceStatus'
import debounce from 'lodash/debounce'
import { searchCarNo } from '@/api/iot/vehicleAccount'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    this.handleLicensePlateNumSearch = debounce(this.handleLicensePlateNumSearch, 500)
    return {
      readOnly: false,
      reMountedForm: true,
      loading: false,
      saveLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        deviceId: null,
        online: undefined,
        deviceTime: null,
        simNo: null,
        plateNo: undefined,
        warnBit: null,
        statusBit: null,
        longitude: null,
        latitude: null,
        altitude: null,
        speed: null,
        direction: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deviceId: [{ required: true, message: '设备编号不能为空', trigger: 'blur' }],
        deviceTime: [{ required: true, message: '设备时间不能为空', trigger: 'change' }],
        simNo: [{ required: true, message: '当前状态的sim卡号不能为空', trigger: 'blur' }],
        plateNo: [{ required: true, message: '当前状态的车牌号不能为空', trigger: 'blur' }],
        warnBit: [{ required: true, message: '报警标志不能为空', trigger: 'blur' }],
        statusBit: [{ required: true, message: '状态不能为空', trigger: 'blur' }],
        longitude: [{ required: true, message: 'GPS经度不能为空', trigger: 'blur' }],
        latitude: [{ required: true, message: 'GPS纬度不能为空', trigger: 'blur' }],
        altitude: [{ required: true, message: '高程(米)不能为空', trigger: 'blur' }],
        speed: [{ required: true, message: '速度(1/10公里每小时)不能为空', trigger: 'blur' }],
        direction: [{ required: true, message: '方向不能为空', trigger: 'blur' }]
      },
      onlineOptions: [
        { dictValue: '0', dictLabel: '离线' },
        { dictValue: '1', dictLabel: '在线' }
      ]
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    open(val) {
      if (val) {
        this.reMountedForm = false
        this.$nextTick(() => {
          this.reMountedForm = true
        })
      }
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.saveLoading = false
      this.form = {
        id: null,
        deviceId: null,
        online: undefined,
        deviceTime: null,
        simNo: null,
        plateNo: undefined,
        warnBit: null,
        statusBit: null,
        longitude: null,
        latitude: null,
        altitude: null,
        speed: null,
        direction: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.readOnly = false
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids, type) {
      this.reset()
      this.formType = 2
      if (type === 'read') {
        this.readOnly = true
      } else {
        this.readOnly = false
      }
      const id = row ? row.id : ids
      getDeviceStatus(id).then(response => {
        this.form = response.data
        this.form.online = this.form.online + ''
        this.open = true
        this.formTitle = '修改'
      })
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      const queryParam = {
        licensePlateNum: value
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleLicensePlateNumChange(value) {
      this.form.licensePlateNum = value
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDeviceStatus(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          } else {
            addDeviceStatus(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
